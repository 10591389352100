import { navigate } from "gatsby";
import { useIntl } from "react-intl";

import React, { useState, FunctionComponent, useRef, Fragment } from "react";
import { Row, Col } from "react-grid-system";

import {
  ServiceLink,
  ServicesTitle,
  ServicesContainer,
  ServiceInfo,
  ServiceImage,
  FloatingLink
} from "./styles";

interface IProps {
  goTo: () => void;
  services: {
    nodes: Array<{
      uid: string;
      data: {
        title: string;
        sub_title: string;
        intro: string;
        active_link: number;
        link_label: { text: string };
        image: { url: string; alt: string };
      };
    }>;
  };
}

const ServicesMobile: FunctionComponent<IProps> = props => {
  const { locale, formatMessage } = useIntl();

  const scrollRef = useRef<HTMLDivElement>(null);
  const {
    services: { nodes: services }
  } = props;

  return (
    <Row id="services" style={{ paddingTop: "10px" }}>
      <Col xs={12}>
        <ServicesContainer ref={scrollRef}>
          <ServicesTitle center>
            {formatMessage({ id: "services" })}
          </ServicesTitle>
          {services.map((service, index) => (
            <Fragment>
              <img
                src={service.data.image.url}
                alt={service.data.title}
              />
              <ServiceInfo>
                <h1>{service.data.title}</h1>
                <h2>{service.data.sub_title}</h2>
                <div id={"service-intro"}>
                  {service.data.intro}
                </div>
                <ServiceLink
                  onClick={() =>
                    service.data.active_link
                      ? navigate(
                          `/${locale}/services/${service.uid}`
                        )
                      : props.goTo()
                  }
                >
                  <span style={{ textTransform: "uppercase" }}>
                    {service.data.link_label.text}
                  </span>
                </ServiceLink>
              </ServiceInfo>
            </Fragment>
          ))}
        </ServicesContainer>
      </Col>
    </Row>
  );
};

export default ServicesMobile;
