import styled from "@emotion/styled";

export const ServicesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  img {
    max-width: 256px;
    margin-bottom: 30px;
    height: auto;
  }
`;

export const ServicesTitle = styled.h2<{ center?: boolean }>`
  font-family: Roboto;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 1.75px;
  color: #f38f1d;
  text-transform: uppercase;
  text-align: ${props => props.center && "center"};
  margin-bottom: ${props => (props.center ? "38px" : "10px")};
`;

export const ServiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 55px;

  h1,
  h2 {
    font-family: Roboto;
    text-align: center;
    line-height: 1.27;
    margin-bottom: 0;
    font-weight: 300;
    font-size: 30px;
  }
  h2 {
    margin-bottom: 25px;
    font-weight: 900;
    color: ${(props: { theme?: any }) => props.theme.colors.decunifyOrange};
  }
  #service-intro {
    font-family: Roboto;
    font-size: 16px;
    line-height: 1.63;
    text-align: center;

    margin-bottom: 16px;
  }
`;

export const ServiceLink = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 176px;
  border: 1px solid #f38f1d;

  span {
    letter-spacing: 1.5px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
  }
`;

export const ServiceImage = styled.img`
  object-fit: cover;

  cursor: pointer;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);

  transition: 1s filter ease, 1s -webkit-filter ease;

  &:hover {
    -webkit-filter: none;
    filter: none;
  }
  &.active {
    box-shadow: -0 -0 3px 3px #fce8c2;
    -webkit-filter: none;
    filter: none;
  }
`;

export const FloatingLink = styled.div`
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;

  ${ServiceLink} {
    background-color: white;
    position: absolute;
    bottom: 60px;
  }
`;
