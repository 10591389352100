import styled from "@emotion/styled";

export const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.img`
  width: 100%;
  min-height: 280px;
  max-height: 420px;
  object-fit: cover;
`;
export const IntroContainer = styled.div`
  padding: 72px 34px;
  background-color: ${(props: { theme?: any }) =>
    props.theme.colors.decunifyOrange};

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: white;

  .banner-title {
    width: 60%;
    font-size: 33px;
    line-height: 1.2;
    min-width: 260px;
    margin-bottom: 64px;
  }
  .banner-description {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 28px;
  }
`;

export const DownArrow = styled.div`
  ${(props: { theme?: any }) => `
        border-bottom: 1px solid ${props.theme.colors.decunifyOrange};
        border-right: 1px solid ${props.theme.colors.decunifyOrange};
        `}
  transform: rotate(45deg);
`;
export const DownButton = styled.div<{ opacity: any }>`
  width: 100%;
  display: flex;
  justify-content: center;

  .circle {
    width: 45px;
    height: 45px;
    cursor: pointer;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    ${DownArrow} {
      width: 13px;
      height: 13px;
    }
  }

  opacity: ${props => props.opacity};
`;
