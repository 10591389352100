import React, { FunctionComponent, useRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  BannerContainer,
  ImageContainer,
  IntroContainer,
  DownButton,
  DownArrow
} from "./styles";

interface ComponentProps {
  scrollFunc(): void;
  image: {
    url: string;
    alt: string;
  };
  welcome_heading?: string;
  welcome_message?: string;
}

const BannerMobile: FunctionComponent<ComponentProps> = props => {
  const { formatMessage } = useIntl();
  const DownButtonRef = useRef<HTMLDivElement>(null);
  const [DownButtonOpacity, SetButtonOpacity] = useState<number>(1);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, { passive: true });
      window.addEventListener("resize", handleScroll);
    }

    return () => {
      // Cleanup
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      }
    };
  }, []);

  // Controls the Down Button's fading value
  const handleScroll = (_e: any) => {
    if (DownButtonRef.current) {
      const mobileHeaderSize = 140;
      const fadingArea = 300;
      const paddingFromBanner = 100;
      const viewPortSize = window.innerHeight;

      const position = DownButtonRef.current?.getBoundingClientRect().top;
      const bottomBreakpoint = viewPortSize * 0.8;
      const topBreakpoint =
        bottomBreakpoint - fadingArea > mobileHeaderSize + paddingFromBanner
          ? bottomBreakpoint - fadingArea
          : mobileHeaderSize + paddingFromBanner;

      if (position > bottomBreakpoint) {
        SetButtonOpacity(1);
      } else if (position < topBreakpoint) {
        SetButtonOpacity(0);
      } else {
        SetButtonOpacity(
          (position - topBreakpoint) / (bottomBreakpoint - topBreakpoint)
        );
      }
    } else {
      SetButtonOpacity(1);
    }
  };

  return (
    <BannerContainer>
      <ImageContainer src={props.image.url} alt={props.image.alt} />
      <IntroContainer>
        <div className="banner-title">
          {props.welcome_heading || formatMessage({ id: "welcome" })}
        </div>
        <div className="banner-description" style={{ marginBottom: "28px" }}>
          {props.welcome_message || formatMessage({ id: "welcome-message" })}
        </div>
        <DownButton
          onClick={props.scrollFunc}
          opacity={DownButtonOpacity}
          ref={DownButtonRef}
        >
          <div className="circle">
            <DownArrow />
          </div>
        </DownButton>
      </IntroContainer>
    </BannerContainer>
  );
};

export default BannerMobile;
