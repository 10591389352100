import React, {
  FunctionComponent,
  useState,
  useRef,
  RefObject,
  useEffect
} from "react";
import { graphql, navigate } from "gatsby";
import StickyBox from "react-sticky-box";
import smoothscroll from "smoothscroll-polyfill";
import { Hidden, Visible, Container, Row, Col } from "react-grid-system";
import ReactVisibilitySensor from "react-visibility-sensor";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

import SideMenu, { SelectionOptions } from "../components/common/SideMenu";
import ServicesMobile from "../components/ServicesMobile";
import BannerMobile from "../components/BannerMobile";
import Layout from "../components/common/Layout";
import CaseStudy from "../components/CaseStudy";
import Solutions from "../components/Solutions";
import Services from "../components/Services";
import Banner from "../components/Banner";
import Blog from "../components/Blog";

import Preview from "../components/common/Preview";

import { useIntl } from "react-intl";

interface IndexPageProps {
  data: {
    backgroundDots: {
      publicURL: string;
      childImageSharp: {
        fixed: any;
      };
    };
    prismicHomepage: {
      data: {
        seo_image: {
          alt: string;
          url: string;
        };
        image?: {
          alt: string;
          url: string;
        };
        title?: string;
        description?: string;
        keywords: string;
        canonical_url?: {
          url: string;
        };
        welcome_heading: {
          text: string;
        };
        welcome_message: string;
        banner_mobile_image: {
          alt: string;
          url: string;
        };
        banner_image: {
          alt: string;
          url: string;
        };
      };
    };
    prismicSolutionIntro: {
      data: {
        title: string;
        intro: { html: string };
        background?: {
          url: string;
        };
      };
    };
    allPrismicSolutionsPreviews: {
      nodes: Array<{
        uid: string;
        data: {
          order: number;
          text: Array<{ text: string; type: string }>;
          title: Array<{ text: string; type: string }>;
          image: { url: string; alt: string };
        };
      }>;
    };
    allPrismicService: {
      nodes: Array<{
        uid: string;
        data: {
          title: string;
          sub_title: string;
          intro: string;
          active_link: number;
          link_label: { text: string };
          image: { url: string; alt: string };
        };
      }>;
    };
    allPrismicCaseStudy: {
      nodes: Array<{
        uid: string;
        data: {
          cover: { url: string; alt: string };
          client: string;
          case_description: string;
        };
      }>;
    };
    allPrismicPost: {
      nodes: Array<{
        uid: string;
        data: {
          cover: { url: string; alt: string };
          title: string;
          intro: string;
          date: string;
        };
      }>;
    };
  };
  location: any;
}

const Index: FunctionComponent<IndexPageProps> = ({ data, location }) => {
  const { locale, formatMessage } = useIntl();
  const [sidemenuSelection, setsidemenuSelection] = useState<SelectionOptions>(
    SelectionOptions.SOLUTIONS
  );
  const [visitedSections, setVisitedSections] = useState({
    solutions: false,
    services: false,
    cases: false,
    blog: false
  });

  const solutionsRef = useRef<HTMLDivElement>(null);
  const serviceRef = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);
  const blogRef = useRef<HTMLDivElement>(null);

  // Automated Scroll on Sidemenu click handling
  const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
    smoothscroll.polyfill();
    if (ref.current && window && document) {
      const headerOffset = 138; // Should be slightly below header size
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = ref.current.getBoundingClientRect().top;
      const elementPosition = elementRect - bodyRect;
      const offsetPosition =
        ref === blogRef || (ref === serviceRef && window.innerWidth >= 1260)
          ? elementPosition
          : elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };
  const handleSideMenuClick = (input: SelectionOptions): void => {
    switch (input) {
      case SelectionOptions.SOLUTIONS:
        scrollToRef(solutionsRef);
        break;
      case SelectionOptions.SERVICES:
        scrollToRef(serviceRef);
        break;
      case SelectionOptions.SUCCESSES:
        scrollToRef(successRef);
        break;
      case SelectionOptions.BLOG:
        scrollToRef(blogRef);
        break;
      default:
        break;
    }
  };

  // Sidemenu indicator changes based on scroll
  const checkRefScroll = (
    ref: RefObject<HTMLDivElement>,
    value: SelectionOptions
  ): boolean => {
    if (ref?.current?.getBoundingClientRect()) {
      const headerOffset = 144;
      const threshold = ref.current.getBoundingClientRect().top - headerOffset;

      if (threshold <= 0) {
        if (sidemenuSelection !== value) {
          setsidemenuSelection(value);
        }
        return true;
      }
    }
    return false;
  };
  const handleScrollListener = (evt: any) => {
    if (document && evt?.type === "scroll") {
      if (!checkRefScroll(blogRef, SelectionOptions.BLOG)) {
        if (!checkRefScroll(successRef, SelectionOptions.SUCCESSES)) {
          if (!checkRefScroll(serviceRef, SelectionOptions.SERVICES)) {
            if (sidemenuSelection !== SelectionOptions.SOLUTIONS) {
              setsidemenuSelection(SelectionOptions.SOLUTIONS);
            }
          }
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScrollListener);

    return () => {
      window.removeEventListener("scroll", handleScrollListener);
    };
  });

  const {
    backgroundDots,
    prismicSolutionIntro,
    allPrismicSolutionsPreviews,
    allPrismicService,
    allPrismicCaseStudy,
    allPrismicPost
  } = data;

  return (
    <Layout
      metaTitle={
        data.prismicHomepage.data.title ||
        formatMessage({ id: "pages.index.meta-title" })
      }
      metaDescription={
        data.prismicHomepage.data.description ||
        formatMessage({ id: "pages.index.meta-description" })
      }
      metaImage={data.prismicHomepage.data.seo_image}
      metaURL={data.prismicHomepage.data.canonical_url?.url}
    >
      <div>
        <div className="banner-desktop decunify">
          <Banner
            image={data.prismicHomepage.data.banner_image}
            scrollFunc={() => scrollToRef(solutionsRef)}
            welcome_heading={data.prismicHomepage.data.welcome_heading.text}
            welcome_message={data.prismicHomepage.data.welcome_message}
          />
        </div>
        <div className="banner-mobile">
          <BannerMobile
            image={data.prismicHomepage.data.banner_mobile_image}
            scrollFunc={() => scrollToRef(solutionsRef)}
            welcome_heading={data.prismicHomepage.data.welcome_heading.text}
            welcome_message={data.prismicHomepage.data.welcome_message}
          />
        </div>
        <div
          style={{
            zIndex: 1,
            backgroundColor: "white",
            backgroundImage: `url(${backgroundDots.publicURL})`,
            backgroundSize: "contain",
            backgroundRepeat: "repeat-y"
          }}
        >
          <div className="container">
            <Row>
              <Hidden xs sm>
                <Col xs={3}>
                  <StickyBox style={{ zIndex: 45 }}>
                    <SideMenu
                      scrollhandler={handleSideMenuClick}
                      selectedOption={sidemenuSelection}
                    />
                  </StickyBox>
                </Col>
              </Hidden>
              <Col xs={12} md={9} style={{ marginBottom: "100px" }}>
                <ReactVisibilitySensor
                  partialVisibility
                  onChange={isVisible => {
                    if (isVisible && !visitedSections.solutions) {
                      trackCustomEvent({
                        category: "section",
                        action: "scroll",
                        label: "Solutions"
                      });
                      setVisitedSections({
                        ...visitedSections,
                        solutions: true
                      });
                    }
                  }}
                >
                  <Solutions
                    reference={solutionsRef}
                    content={prismicSolutionIntro}
                    previews={allPrismicSolutionsPreviews}
                    skipAnimation={
                      location.state && location.state.skipSolutionsAnimation
                    }
                  />
                </ReactVisibilitySensor>
                <div ref={serviceRef}>
                  <Visible xl>
                    <ReactVisibilitySensor
                      partialVisibility
                      onChange={isVisible => {
                        if (isVisible && !visitedSections.services) {
                          trackCustomEvent({
                            category: "section",
                            action: "scroll",
                            label: "Services"
                          });
                          setVisitedSections({
                            ...visitedSections,
                            services: true
                          });
                        }
                      }}
                    >
                      <Services
                        services={allPrismicService}
                        goTo={`/${locale}/contact-us`}
                      />
                    </ReactVisibilitySensor>
                  </Visible>
                  <Visible xs sm md lg>
                    <ReactVisibilitySensor
                      partialVisibility
                      onChange={isVisible => {
                        if (isVisible && !visitedSections.services) {
                          trackCustomEvent({
                            category: "section",
                            action: "scroll",
                            label: "Services"
                          });
                          setVisitedSections({
                            ...visitedSections,
                            services: true
                          });
                        }
                      }}
                    >
                      <ServicesMobile
                        services={allPrismicService}
                        goTo={() => navigate(`/${locale}/contact-us`)}
                      />
                    </ReactVisibilitySensor>
                  </Visible>
                </div>
                <ReactVisibilitySensor
                  partialVisibility
                  onChange={isVisible => {
                    if (isVisible && !visitedSections.cases) {
                      trackCustomEvent({
                        category: "section",
                        action: "scroll",
                        label: "Case Studies"
                      });
                      setVisitedSections({
                        ...visitedSections,
                        cases: true
                      });
                    }
                  }}
                >
                  <CaseStudy
                    reference={successRef}
                    caseStudy={allPrismicCaseStudy}
                  />
                </ReactVisibilitySensor>
                <ReactVisibilitySensor
                  partialVisibility
                  onChange={isVisible => {
                    if (isVisible && !visitedSections.blog) {
                      trackCustomEvent({
                        category: "section",
                        action: "scroll",
                        label: "Blog"
                      });
                      setVisitedSections({
                        ...visitedSections,
                        blog: true
                      });
                    }
                  }}
                >
                  <Blog reference={blogRef} posts={allPrismicPost} />
                </ReactVisibilitySensor>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!) {
    prismicHomepage(lang: { eq: $lang }) {
      data {
        canonical_url {
          url
        }
        seo_image {
          alt
          url
        }
        title
        description
        welcome_heading {
          text
        }
        welcome_message
        banner_mobile_image {
          url
          alt
        }
        banner_image {
          url
          alt
        }
      }
    }
    backgroundDots: file(
      sourceInstanceName: { eq: "static" }
      relativePath: { eq: "images/Background-transparent.png" }
    ) {
      publicURL
    }
    prismicSolutionIntro(lang: { eq: $lang }) {
      data {
        title
        intro {
          html
        }
      }
    }
    allPrismicSolutionsPreviews(
      sort: { fields: data___order }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        data {
          image {
            url
            alt
          }
          text {
            text
          }
          title {
            text
          }
          order
        }
        uid
      }
    }
    allPrismicService(filter: { lang: { eq: $lang } }) {
      nodes {
        uid
        data {
          title
          sub_title
          intro
          active_link
          link_label {
            text
          }
          image {
            url
            alt
          }
        }
      }
    }
    allPrismicCaseStudy(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        data {
          client
          case_description
          cover {
            alt
            url
          }
        }
        uid
      }
    }
    # All posts are meant to be Portuguese, swap this in to revert that change
    # { lang: { eq: $lang } }
    allPrismicPost(
      filter: { lang: { eq: "pt-pt" } }
      limit: 3
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        data {
          preview_image {
            alt
            url
          }
          date
          intro
          title
        }
        uid
        lang
      }
    }
  }
`;

export default Preview(Index);
